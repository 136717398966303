// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CRu6gZUeE", "XCJrZVMBU"];

const variantClassNames = {CRu6gZUeE: "framer-v-mf7oxj", XCJrZVMBU: "framer-v-199461g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Button Primary": "CRu6gZUeE", "Grey Hover": "XCJrZVMBU"};

const transitions = {default: {damping: 2, delay: 0, duration: 0, ease: [0, 0, 1, 1], mass: 10, stiffness: 13, type: "tween"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; contactButton?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "CRu6gZUeE", contactButton: Sneg04CwL = "Contact", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CRu6gZUeE", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1ka5orp = activeVariantCallback(async (...args) => {
setVariant("XCJrZVMBU")
})

const onMouseLeavegkdsii = activeVariantCallback(async (...args) => {
setVariant("CRu6gZUeE")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6WNR1", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-mf7oxj", className)} data-border data-framer-name={"Button Primary"} data-highlight layoutDependency={layoutDependency} layoutId={"CRu6gZUeE"} onMouseEnter={onMouseEnter1ka5orp} onMouseLeave={onMouseLeavegkdsii} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(221, 221, 221)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(255, 255, 255, 0)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} transformTemplate={transformTemplate} transition={transition} variants={{XCJrZVMBU: {backgroundColor: "rgb(54, 54, 54)"}}} {...addPropertyOverrides({XCJrZVMBU: {"data-framer-name": "Grey Hover"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Get Started</motion.p></React.Fragment>} className={"framer-1jnsvq1"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"no5uv0NQt"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={Sneg04CwL} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6WNR1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6WNR1 * { box-sizing: border-box; }", ".framer-6WNR1 .framer-3jjfm1 { display: block; }", ".framer-6WNR1 .framer-mf7oxj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 15px 15px 15px 15px; position: relative; width: min-content; will-change: transform; }", ".framer-6WNR1 .framer-1jnsvq1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6WNR1 .framer-mf7oxj { gap: 0px; } .framer-6WNR1 .framer-mf7oxj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6WNR1 .framer-mf7oxj > :first-child { margin-left: 0px; } .framer-6WNR1 .framer-mf7oxj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 85
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"XCJrZVMBU":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Sneg04CwL":"contactButton"}
 */
const FramerMvS5FTd3v: React.ComponentType<Props> = withCSS(Component, css, "framer-6WNR1") as typeof Component;
export default FramerMvS5FTd3v;

FramerMvS5FTd3v.displayName = "Button";

FramerMvS5FTd3v.defaultProps = {height: 40, width: 85};

addPropertyControls(FramerMvS5FTd3v, {variant: {options: ["CRu6gZUeE", "XCJrZVMBU"], optionTitles: ["Button Primary", "Grey Hover"], title: "Variant", type: ControlType.Enum}, Sneg04CwL: {defaultValue: "Contact", displayTextArea: false, placeholder: "", title: "Contact Button", type: ControlType.String}} as any)

addFonts(FramerMvS5FTd3v, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/MvS5FTd3v:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])